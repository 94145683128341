import { useLocation } from '@reach/router';

const useDataScienceProgram = () => {
  const location = useLocation();

  const isDataSciencePage = location.pathname === '/ai-data-analytics/';

  return { isDataSciencePage };
};

export default useDataScienceProgram;
