import React from 'react';
import { TextField } from '@mui/material';
import { useFormContext, Controller } from 'react-hook-form';
import FormIcon from './FormIcon';

export default function FormInput({
  name,
  label,
  icon,
  isOpenForm,
  handleFocus,
}) {
  const formContext = useFormContext();
  const error = formContext.formState.errors[name]?.message;

  return (
    <div className="relative inline-block">
      <Controller
        name={name}
        control={formContext.control}
        render={({ field }) => (
          <TextField
            variant="filled"
            label={label}
            {...field}
            helperText={error}
            fullWidth
            FormHelperTextProps={{
              style: {
                color: '#F3736A',
              },
            }}
            onFocus={handleFocus}
            InputProps={{
              disableUnderline: true,
              style: {
                color: '#F2FBF7',
                padding: 3,
                borderRadius: '16px',
              },
            }}
            InputLabelProps={{
              style: {
                ...(error ? { color: '#F3736A' } : { color: '#009378' }),
              },
            }}
            className={`${
              error
                ? 'bg-v5-red-500'
                : isOpenForm
                ? 'bg-v5-green-700'
                : 'bg-v5-green-500'
            } rounded-2xl text-v5-neutral-200 placeholder:text-v5-green-300`}
            inputProps={{ 'aria-label': `Enter ${label}` }}
          ></TextField>
        )}
      ></Controller>
      <FormIcon
        icon={icon}
        error={error}
        validated={
          formContext.formState.dirtyFields[name] &&
          !formContext.formState.errors[name]
        }
      />
    </div>
  );
}
