import React from 'react';
import { MenuItem, TextField } from '@mui/material';
import { useFormContext, Controller } from 'react-hook-form';
import FormIcon from './FormIcon';

export default function FormDropDown({
  name,
  menu_items,
  icon,
  label,
  isOpenForm,
  handleFocus,
  isRequired,
}) {
  const formContext = useFormContext();
  const error = formContext.formState.errors[name]?.message;

  return (
    <Controller
      name={name}
      render={({ field: { onChange, onBlur, value, ref } }) => {
        return (
          <TextField
            value={value}
            required={isRequired}
            select
            variant="filled"
            defaultValue=""
            onFocus={handleFocus}
            name={name}
            label={label}
            helperText={error}
            onBlur={onBlur}
            ref={ref}
            onChange={onChange}
            fullWidth
            InputProps={{
              disableUnderline: true,
              style: {
                color: '#F2FBF7',
                borderRadius: '1rem',
                padding: 3,
                textAlign: 'left',
              },
            }}
            InputLabelProps={{
              style: {
                ...(error ? { color: '#F3736A' } : { color: '#009378' }),
              },
            }}
            FormHelperTextProps={{
              style: {
                color: '#F3736A',
              },
            }}
            className={`${
              error
                ? 'bg-v5-red-500'
                : isOpenForm
                ? 'bg-v5-green-700'
                : 'bg-v5-green-500'
            } rounded-2xl text-v5-neutral-200`}
            inputProps={{
              'aria-label': `Enter ${label}`,
            }}
            SelectProps={{
              IconComponent: () => (
                <FormIcon
                  icon={icon}
                  error={error}
                  validated={
                    formContext.formState.dirtyFields[name] &&
                    !formContext.formState.errors[name]
                  }
                  className="p-3"
                />
              ),
              MenuProps: {
                PaperProps: {
                  style: {
                    borderBottomRightRadius: 16,
                    borderBottomLeftRadius: 16,
                    maxHeight: '140px',
                    borderBottom: '12px',
                    marginTop: 1,
                    zIndex: 1,
                  },
                  sx: {
                    bgcolor: isOpenForm ? '#013437' : '#003B3F',
                    color: '#B9CFCA',
                    '&::-webkit-scrollbar': {
                      width: 24,
                    },
                    '&::-webkit-scrollbar-track': {
                      backgroundColor: '#013437',
                      borderRadius: '12px',
                      border: `4px solid #003B3F`,
                      backgroundClip: 'content-box',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: '#009378',
                      borderRadius: 1000,
                      border: '4px solid #003B3F',
                      height: 24,
                      width: 24,
                    },
                  },
                },
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
              },
            }}
          >
            {menu_items.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        );
      }}
    ></Controller>
  );
}
