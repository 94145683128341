import React from 'react';
import ReactDOM from 'react-dom';
import EmailCapturePopup from './src/components/EmailCapturePopup';
import ChatBotComponent from './src/components/ChatBotComponent';
import { LocationProvider } from '@reach/router';
import Root from './src/Root';
import { navigate } from 'gatsby';
import { ThemeProvider } from 'styled-components';
import {
  ThemeProvider as MuiThemeProvider,
  createTheme,
} from '@mui/material/styles';
import DialogProvider from './src/components/v5/Dialog/index';
import GlobalContextProvider from './src/context/GlobalContextProvider';
import { ProvideAuth } from './src/firebase/useAuth';
import './src/styles/bootstrap.min.css';
import './src/css/typography.css';
import './src/sass/global.scss';
import './src/pages/index.css';
import './src/styles/tailwind.css';
import MarketingPopup from './src/components/Popups/MarketingPopup';

const enableOnPaths = [
  '/',
  '/software-development-fellowship-program/',
  '/fellowship-program-software-developer/',
  '/full-stack-developer-track/ppc-ads/',
  '/qa-automation-testing/',
  '/backend-developer-track/',
  '/backend-developer-track-v2/',
  '/full-stack-developer-track/',
  '/full-stack-developer-track-v2/',
];

const marketingPopupEnableOnPaths = [
  '/',
  '/software-development-fellowship-program/',
  '/qa-automation-testing/',
  '/full-stack-developer-track/ppc-ads/',
  '/projects/',
];

// all available props
const theme = {
  background: '#f5f8fb',
  fontFamily:
    'Rubik,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol',
  headerBgColor: '#7bba8c',
  headerFontColor: '#fff',
  headerFontSize: '15px',
  botBubbleColor: '#fff',
  botFontColor: '#4a4a4a',
  userBubbleColor: '#7bba8c',
  userFontColor: '#fff',
};

const muiTheme = createTheme({
  palette: {
    primary: {
      main: '#7bba8c',
    },
    yellow: '#FF980E',
    secondary: {
      main: '#075353',
    },
  },
});
const stripTrailingSlash = (str) =>
  str.charAt(str.length - 1) == '/' && str.length > 1
    ? str.substr(0, str.length - 1)
    : str;

export const replaceHydrateFunction = () => {
  if (!['/'].includes(stripTrailingSlash(window.location.pathname))) {
    return (element, container, callback) => {
      ReactDOM.render(element, container, callback);
    };
  }
};

export function shouldUpdateScroll({ routerProps: { location } }) {
  if (!Boolean(location.hash)) {
    window.scrollTo(0, 0);
    const body = document.getElementsByTagName('body')[0];
    body.scrollTop = 0;
    return false;
  }
}

export function onRouteUpdate({ location }) {
  if (
    location.pathname.includes('/software-development-fellowship-program-plus')
  ) {
    navigate('/fellowship-program-system-design');
  }
}

export const wrapRootElement = ({ element }) => (
  <LocationProvider>
    <GlobalContextProvider>
      <ProvideAuth>
        <MuiThemeProvider theme={muiTheme}>
          <DialogProvider>
            <Root>
              <ThemeProvider theme={theme}>{element}</ThemeProvider>
            </Root>
          </DialogProvider>
        </MuiThemeProvider>
      </ProvideAuth>
    </GlobalContextProvider>
  </LocationProvider>
);

export const wrapPageElement = ({ element, props }) => {
  console.log(window?.location?.pathname);
  return (
    <>
      {element}
      {enableOnPaths.some((ele) => window?.location?.pathname === ele) && (
        <>
          <EmailCapturePopup sale={props?.data?.strapiSale?.SaleID || ''} />

          <ChatBotComponent />
        </>
      )}
      {marketingPopupEnableOnPaths.some(
        (ele) => window?.location?.pathname === ele,
      ) && <MarketingPopup />}
    </>
  );
};
