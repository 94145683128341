import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import FormIcon from './FormIcon';
import { Paper } from '@mui/material/index';
import { cleanSpecialCharacters } from '@src/constants/LeadGenerationConstants/index';

export default function FormAutoComplete({
  name,
  menu_items,
  icon,
  label,
  isOpenForm,
  handleFocus,
}) {
  const formContext = useFormContext();
  const error = formContext.formState.errors[name]?.message;

  return (
    <div className="relative inline-block">
      <Controller
        control={formContext.control}
        name={name}
        render={({ field: { onChange, value } }) => {
          return (
            <Autocomplete
              freeSolo
              onFocus={handleFocus}
              clearIcon={false}
              forcePopupIcon={false}
              options={menu_items}
              getOptionLabel={(option) => {
                if (typeof option === 'string') {
                  return option;
                }

                if (option.value) return option.value;

                return option.label;
              }}
              filterOptions={(options, params) => {
                const { inputValue } = params;
                let filtered;

                if (inputValue === '')
                  filtered = [
                    { label: 'Type to search', value: '', disabled: true },
                  ];
                else
                  filtered = options.filter((option) =>
                    cleanSpecialCharacters(option.value).includes(
                      cleanSpecialCharacters(inputValue),
                    ),
                  );

                // Suggest the creation of a new value
                const isExisting = options.some(
                  (option) =>
                    inputValue.toLowerCase() === option.value.toLowerCase(),
                );
                if (inputValue !== '' && !isExisting) {
                  filtered.push({
                    label: `Create Option "${inputValue}"`,
                    value: inputValue,
                    isAddOn: true,
                  });
                }

                return filtered;
              }}
              onChange={(event, values) => {
                onChange(values?.value || '');
              }}
              value={menu_items.find((item) => item.value === value) || null}
              getOptionDisabled={(option) => {
                return option.disabled;
              }}
              ListboxProps={{ className: 'scrollbar max-h-[200px]' }}
              PaperComponent={(props) => {
                return (
                  <Paper
                    {...props}
                    sx={{
                      '&::-webkit-scrollbar': {
                        width: 20,
                      },
                      '&::-webkit-scrollbar-track': {
                        backgroundColor: '#013437',
                        borderRadius: '12px',
                        border: `4px solid #003B3F`,
                        backgroundClip: 'content-box',
                      },
                      '&::-webkit-scrollbar-thumb': {
                        backgroundColor: '#009378',
                        borderRadius: 1000,
                        border: '4px solid #003B3F',
                        height: 20,
                        width: 20,
                      },
                    }}
                    className={`pb-2 ${
                      isOpenForm ? 'bg-[#013437]' : 'bg-[#003B3F]'
                    } -mt-1 overflow-auto rounded-b-xl`}
                  ></Paper>
                );
              }}
              renderOption={(props, option) => {
                return (
                  <div
                    {...props}
                    {...(option.disabled ? { onClick: () => {} } : {})}
                    className={`${
                      option.disabled
                        ? 'disabled'
                        : `cursor-pointer ${
                            option.value === value
                              ? 'bg-[#0F4B48]'
                              : 'hover:bg-[#00393d]'
                          }`
                    }   px-4 py-2 text-[#A1BCB8] ${
                      isOpenForm ? 'bg-[#013437]' : 'bg-[#003B3F]'
                    }`}
                  >
                    {option.label}
                  </div>
                );
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    label={label}
                    variant="filled"
                    onChange={onChange}
                    helperText={error}
                    fullWidth
                    FormHelperTextProps={{
                      ...params.FormHelperTextProps,
                      style: {
                        color: '#F3736A',
                      },
                    }}
                    InputProps={{
                      ...params.InputProps,
                      disableUnderline: true,
                      style: {
                        color: '#F2FBF7',
                        minHeight: '62px',
                        borderRadius: '16px',
                      },
                    }}
                    InputLabelProps={{
                      ...params.InputLabelProps,
                      style: {
                        ...(error
                          ? { color: '#F3736A' }
                          : { color: '#009378' }),
                      },
                    }}
                    className={`${
                      error
                        ? 'bg-v5-red-500'
                        : isOpenForm
                        ? 'bg-v5-green-700'
                        : 'bg-v5-green-500'
                    } rounded-2xl placeholder:text-v5-green-300`}
                  />
                );
              }}
            />
          );
        }}
      ></Controller>
      <FormIcon
        icon={icon}
        error={error}
        validated={
          formContext.formState.dirtyFields[name] &&
          !formContext.formState.errors[name]
        }
      />
    </div>
  );
}
